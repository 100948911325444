var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"fixBottom1"},[_c('div',{staticClass:"bwhite"},[_c('div',{staticClass:"blogo",on:{"click":_vm.goindex}},[_c('img',{attrs:{"src":require("../assets/images/xuexi/logo.png")}})]),_vm._m(0),_c('div',{staticClass:"blianxibtn"},[_c('img',{attrs:{"src":require("../assets/images/xuexi/lianxi.png")},on:{"click":_vm.gotuite}})]),_c('div',{staticClass:"bshuxian"}),_c('div',{staticClass:"bshuxian2"}),_c('div',{staticClass:"faq",on:{"click":_vm.goknowledge}},[_c('img',{attrs:{"src":require("../assets/images/index/faq.png")}})]),_c('div',{staticClass:"bshu1"},[_c('div',{staticClass:"shutitle"},[_vm._v("ABOUT")]),_c('div',{staticClass:"shuContent"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                name: 'news',
              })}}},[_vm._v("News Releases")]),_c('a',{on:{"click":function($event){return _vm.$router.push({
                name: 'job',
              })}}},[_vm._v("Careers")]),_c('a',{on:{"click":function($event){return _vm.$router.push({
                name: 'blog',
              })}}},[_vm._v("Blogs")])])]),_c('div',{staticClass:"bshu4"},[_c('div',{staticClass:"shutitle"},[_vm._v(" ")]),_c('div',{staticClass:"shuContent"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                name: 'activity',
              })}}},[_vm._v("Events")])]),_c('div',{staticClass:"shuContent"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                name: 'team',
              })}}},[_vm._v("Team")])])]),_c('div',{staticClass:"bshu2"},[_c('div',{staticClass:"shutitle"},[_vm._v("LEGAL")]),_c('div',{staticClass:"shuContent"},[_c('a',{attrs:{"target":"_blank","href":_vm.href + 'file/20231219SgtoxPlatformTermsAndConditions.pdf'}},[_vm._v("Terms and Condition")]),_c('a',{attrs:{"target":"_blank","href":_vm.href + 'file/20231215SgtoxPrivacyPolicy.pdf'}},[_vm._v("Privacy")]),_c('a',{attrs:{"target":"_blank","href":_vm.href + 'file/20231215SgtoxCookiesPolicy.pdf'}},[_vm._v("Cookie Policy")]),_c('a',{attrs:{"target":"_blank","href":_vm.href + 'file/20240102SgtoxExchangeRules.pdf'}},[_vm._v("Exchange Rules")])])]),_c('div',{staticClass:"bshu3"},[_c('div',{staticClass:"shutitle"},[_vm._v(" ")]),_c('div',{staticClass:"shuContent"},[_c('a',{attrs:{"target":"_blank","href":_vm.href + 'file/20240102SgtoxListingRules.pdf'}},[_vm._v("Listing Rules")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Cyber Security Tips")]),_c('a',{attrs:{"target":"_blank","href":_vm.href + 'file/20231215SgtoxRegulatoryDisclosures.pdf'}},[_vm._v("Regulatory Disclosure")]),_c('a',{attrs:{"target":"_blank","href":_vm.href + 'file/20240102SgtoxComunityChannelsTnC.pdf'}},[_vm._v("Community Channel")])])])])]),_vm._m(1),(_vm.cookieShow)?_c('div',{ref:"fixcookie",staticClass:"fixCookie"},[_c('div',{staticClass:"safeW"},[_c('div',{staticClass:"tiaokuantxt"},[_vm._v(" By using this website, you agree to "),_c('a',{attrs:{"target":"_blank","href":_vm.href + 'file/20231215SgtoxPrivacyPolicy.pdf'}},[_vm._v("our Privacy Policy")]),_vm._v(" and "),_c('a',{attrs:{"target":"_blank","href":_vm.href + 'file/20231215SgtoxCookiesPolicy.pdf'}},[_vm._v("Terms of Use")])]),_c('div',{staticClass:"tongyi",on:{"click":_vm.tongyi}},[_vm._v("Agree")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blogodesc"},[_vm._v(" The First All-encompassing "),_c('br'),_vm._v(" Asset Token Exchange ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixBottom2"},[_c('div',{staticClass:"bblue"},[_vm._v(" Singapore Asset Token Exchange PTE. LTD. 新加坡通证资产交易所"),_c('br'),_vm._v(" @All rights reserved 2023 Level 11, Marina Bay Financial Center Tower 1, 8 Marina Blvd, 018981 Singapore ")])])
}]

export { render, staticRenderFns }