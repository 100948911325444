import { getMessages } from '@/api/home'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token'),
    userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
    unReadMessage: [],
    total_count: 0,
  },
  getters: {
    getUser: (state) => {
      return state.userInfo
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      localStorage.setItem('token', token)
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    REMOVE_INFO: (state) => {
      localStorage.setItem('token', '')
      sessionStorage.setItem('userInfo', JSON.stringify(''))
      state.userInfo = {}
    },
    setUnreadMessage(state, payload) {
      state.unReadMessage = payload
    },
    setTotalCount(state, payload) {
      state.total_count = payload
    },
  },
  actions: {
    asyncSetMessage({ commit }) {
      getMessages(1, 5, 1).then((res) => {
        commit('setUnreadMessage', res.data.messages)
        commit('setTotalCount', res.data.total_count)
      })
    },
  },
  modules: {},
})
