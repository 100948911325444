<template>
  <div class="bg">
    <Header :currentNav="0"></Header>

    <div class="fullImg">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" style="cursor: pointer">
            <div><img src="../assets/images/index/banner.jpg" /></div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="fullImg">
      <div><img src="../assets/images/index/homebar1.jpg" /></div>
    </div>

    <div class="fullImg">
      <div><img src="../assets/images/index/homebar2.jpg" /></div>
    </div>

    <div class="fullImg" @click="goinvestList" style="cursor: pointer">
      <div><img src="../assets/images/index/homebar3.jpg" /></div>
    </div>

    <div class="fullImg">
      <div class="logoBox">
        <img class="bn" src="../assets/images/index/homebar4.jpg" />

        <div class="logoList">
          <div class="l" style="flex: 1">
            <div class="row">
              <div class="l">
                <div>Technology Platform</div>
              </div>
              <a href="https://www.chainup.com" target="_blank">
                <img
                  src="../assets/images/icons/row11.png"
                  alt=""
                  class="row11"
              /></a>
              <a href=" https://www.sgtox.com" target="_blank">
                <img
                  src="../assets/images/icons/row12.png"
                  alt=""
                  class="row12"
              /></a>
              <a href=" https://sg.alibabacloud.com" target="_blank">
                <img
                  src="../assets/images/icons/row13.png"
                  alt=""
                  class="row13"
              /></a>
              <a href="#">
                <img
                  src="../assets/images/icons/row14.png"
                  alt=""
                  class="row13"
              /></a>
            </div>
            <div class="row">
              <div class="l">
                <div>Banks</div>
              </div>
              <a href="https://eng.spdb.com.cn/" target="_blank">
                <img
                  src="../assets/images/icons/row21.png"
                  alt=""
                  class="row21"
              /></a>
              <a href="https://sg.cmbi.com/en-US/" target="_blank">
                <img
                  src="../assets/images/icons/row22.png"
                  alt=""
                  class="row22"
              /></a>
              <a href="https://www.qnb.com/" target="_blank">
                <img
                  src="../assets/images/icons/row23.png"
                  alt=""
                  class="row23"
              /></a>
            </div>
            <div class="row">
              <div class="l">
                <div>Asset Managers</div>
              </div>
              <a href="http://www.nanhua-am.sg/" target="_blank">
                <img
                  src="../assets/images/icons/row31.png"
                  alt=""
                  class="row31"
              /></a>
              <a href="http://www.cwsi.com.hk/" target="_blank">
                <img
                  src="../assets/images/icons/row32.png"
                  alt=""
                  class="row32"
              /></a>
              <a href="https://penco-capital.com/" target="_blank">
                <img
                  src="../assets/images/icons/row33.png"
                  alt=""
                  class="row33"
              /></a>
            </div>
            <div class="row">
              <div class="l">
                <div>Real Estate</div>
              </div>
              <a href="https://www.propnex.com/" target="_blank">
                <img
                  src="../assets/images/icons/row41.png"
                  alt=""
                  class="row41"
              /></a>
              <a href="https://www.pgbc.com.my/" target="_blank">
                <img
                  src="../assets/images/icons/row42.png"
                  alt=""
                  class="row42"
              /></a>
              <a href="#" target="_blank">
                <img
                  src="../assets/images/icons/row43.png"
                  alt=""
                  class="row43"
              /></a>
              <a href="https://www.linbaq.com" target="_blank">
                <img
                  src="../assets/images/icons/row44.png"
                  alt=""
                  class="row44"
              /></a>
            </div>
            <div class="row">
              <div class="l">
                <div>Intangible Asset</div>
              </div>
              <a href="https://www.iq.com/" target="_blank">
                <img
                  src="../assets/images/icons/row51.png"
                  alt=""
                  class="row51"
              /></a>
            </div>
          </div>
          <div class="r">
            <div class="row">
              <div class="l">
                <div>Sustainability</div>
              </div>
              <a href="https://www.climateimpactx.com/" target="_blank">
                <img
                  src="../assets/images/icons/row61.png"
                  alt=""
                  class="row61"
              /></a>
              <a href="http://www.tandidi.com" target="_blank">
                <img
                  src="../assets/images/icons/row62.png"
                  alt=""
                  class="row62"
              /></a>
              <a href="https://www.milltrust.com/" target="_blank">
                <img
                  src="../assets/images/icons/row63.png"
                  alt=""
                  class="row63"
              /></a>
            </div>
            <div class="row">
              <div class="l">
                <div>VC / Community</div>
              </div>
              <a href="https://www.abcde.com/" target="_blank">
                <img
                  src="../assets/images/icons/row71.png"
                  alt=""
                  class="row71"
              /></a>
              <a href="http://www.iccafe.sg/" target="_blank">
                <img
                  src="../assets/images/icons/row72.png"
                  alt=""
                  class="row72"
              /></a>
              <a href="https://uweb.ai/en/" target="_blank">
                <img
                  src="../assets/images/icons/row73.png"
                  alt=""
                  class="row73"
              /></a>
              <a href="https://alchemypay.org/" target="_blank">
                <img
                  src="../assets/images/icons/row74.png"
                  alt=""
                  class="row74"
              /></a>
            </div>
            <div class="row">
              <div class="l">
                <div>Research Institute and Associations</div>
              </div>
              <a href="https://globalfintechinstitute.org/" target="_blank">
                <img
                  src="../assets/images/icons/row81.png"
                  alt=""
                  class="row81"
              /></a>
              <a
                href="https://fisf.fudan.edu.cn/page-GFRC.html"
                target="_blank"
              >
                <img
                  src="../assets/images/icons/row82.png"
                  alt=""
                  class="row82"
              /></a>
              <a href="https://nus.edu.sg/" target="_blank">
                <img
                  src="../assets/images/icons/row83.png"
                  alt=""
                  class="row83"
              /></a>
            </div>
            <div class="row">
              <div class="l">
                <div>Adviser</div>
              </div>
              <a href="https://www.anext.com.sg/" target="_blank">
                <img
                  src="../assets/images/icons/row91.png"
                  alt=""
                  class="row91"
              /></a>
              <a href="https://singaporefintech.org/" target="_blank">
                <img
                  src="../assets/images/icons/row92.png"
                  alt=""
                  class="row92"
              /></a>
              <a href="https://singaporeblockchain.org/" target="_blank">
                <img
                  src="../assets/images/icons/row93.png"
                  alt=""
                  class="row93"
              /></a>
            </div>
          </div>
        </div>
        <div class="remore" @click="goAprtp">VIEW MORE</div>
      </div>
    </div>
    <div class="fullImg">
      <div><img src="../assets/images/index/homebar5.jpg" /></div>
    </div>

    <div class="fullImg">
      <div><img src="../assets/images/index/homebar6.jpg" /></div>
    </div>

    <div class="qabox">
      <div class="safeW">
        <div class="qatitle">Frequently Asked Questions</div>
        <div class="qacontainer">
          <div class="qaUnit" v-for="(item, index) in qList" :key="index">
            <div class="q" @click="qslide(index)">
              <i></i>
              <div class="arrowdown">
                <i
                  class="el-icon-arrow-up"
                  v-if="qStateList[index] == true"
                ></i>
                <i class="el-icon-arrow-down" v-else></i>
              </div>
              {{ item.article_title }}
            </div>
            <div class="a" v-if="qStateList[index] == true">
              <div v-html="item.article_content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Bottom></Bottom>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Bottom from '@/components/Bottom'
import { smsCode, loginMobile, getPost, getGet } from '../api/apiList'
import qs from 'qs'
export default {
  components: { Header, Bottom },
  data() {
    return {
      qStateList: [],
      qList: [],
      indexSwipe: {},
    }
  },
  created() {},
  mounted() {
    // var that = this
    // this.indexSwipe = new Swiper('.swiper-container', {
    //   autoplay: false,
    //   loop: true,
    //   pagination: {
    //     el: '.swiper-pagination',
    //   },
    //   on: {
    //     tap: function (swiper, event) {
    //       that.$router.push({
    //         path: '/reg',
    //       })
    //     },
    //     resize:function(){
    //       setTimeout(()=>{
    //         that.indexSwipe.update();
    //       },100)
    //     }
    //   },
    // })

    setTimeout(() => {
      this.initSwipe()
    }, 1000)

    //this.qStateList = [];

    this.getIndexQa()
  },
  methods: {
    goAprtp() {
      window.open(' https://aprtp.com')
    },
    initSwipe() {
      var that = this
      this.indexSwipe = new Swiper('.swiper-container', {
        autoplay: true,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
        },
        on: {
          tap: function (swiper, event) {
            that.$router.push({
              path: '/reg',
            })
          },
          resize: function () {
            setTimeout(() => {
              that.indexSwipe.update()
            }, 100)
          },
        },
      })
    },

    goreg() {
      this.$router.push({
        path: '/reg',
      })
    },

    goinvestList() {
      this.$router.push({
        path: '/investList',
      })
    },

    getIndexQa() {
      let url1 = 'stox_home/index_qa'
      let data1 = {}

      getPost(url1, data1).then((res) => {
        if (res.code == 1) {
          this.qList = res.data

          for (var i = 0; i < res.data.length; i++) {
            this.qStateList.push(false)
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    qslide(i) {
      // console.log('e',e);
      for (var j = 0; j < this.qList.length; j++) {
        if (j == i) {
        } else {
          this.qStateList[j] = false
        }
      }
      this.qStateList[i] = !this.qStateList[i]
      this.$forceUpdate()
      console.log('qStateList', this.qStateList)
    },
  },
  watch: {},
}
</script>

<style lang="less" scoped>
.bg {
  width: 100%;
}

.fullImg {
  position: relative;
  min-width: 1280px;
  overflow-x: hidden;
  margin: 0 auto;
  > div {
    position: relative;
    width: 1920px;
    left: 50%;
    margin-left: -960px;
    img {
      width: 1920px;
      vertical-align: bottom;
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-container > .swiper-wrapper > .swiper-slide {
  text-align: center;
  font-size: 18px;
  position: relative;

  width: 100%;
  height: 725px;
}
::v-deep .swiper-container {
  // --swiper-theme-color: #ff6600;
  .swiper-pagination-bullet {
    background: white;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: blue;
  }
}

.qabox {
  background: #fafafa;
  background: url('../assets/images/index/faqbg.png') no-repeat;
  background-position: right 0;
}

.safeW {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 70px 0;
}
.qatitle {
  color: #2f2f2f;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 94px;
}

.qaUnit {
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 10px #ccc;
  margin-bottom: 20px;
  > .q {
    font-size: 20px;
    color: #000;
    padding: 20px;
    padding-left: 40px;
    padding-right: 60px;
    position: relative;
    cursor: pointer;
    > i {
      position: absolute;
      left: 20px;
      top: 23px;
      width: 4px;
      height: 22px;
      background: #da3c3f;
    }
    > .arrowdown {
      position: absolute;
      right: 20px;
      top: 23px;
      i {
        font-weight: 700;
        font-size: 30px;
      }
    }
  }
  > .a {
    border-top: 1px solid #dcddde;
    padding: 20px;
    font-size: 16px;
    word-break: break-word;
    color: #666;
    > .more {
      text-align: right;
      text-decoration: underline;
      color: #6b89e7;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.logoBox {
  width: 100%;
  position: relative;
  .logoList {
    display: flex;
    width: 70%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 900px;
    height: 400px;
    .r {
      flex: 1;
    }
  }
}
.remore {
  text-align: center;
  color: #fff;
  position: relative;
  top: -100px;
  cursor: pointer;
  line-height: 46px;
  font-size: 20px;
  margin: 0 auto;
  width: 239px;
  height: 46px;
  background: #ce0d1b;
  border-radius: 9px;
  margin-bottom: 100px;
}
.row {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .l {
    text-align: right;
    width: 151px;
    margin-right: 30px;
  }
  img {
    width: auto !important;
    margin-right: 10px;
  }
}

.row11 {
  height: 20px;
}
.row12 {
  height: 20px;
}
.row13 {
  height: 29px;
}
.row21 {
  height: 52px;
}
.row22 {
  height: 70px;
}
.row31 {
  height: 29px;
}
.row32 {
  height: 54px;
}
.row33 {
  height: 45px;
}
.row41 {
  height: 50px;
}
.row42 {
  height: 52px;
}
.row43 {
  height: 53px;
}
.row44 {
  height: 27px;
}
.row51 {
  height: 25px;
}
.row61 {
  height: 42px;
}
.row62 {
  height: 39px;
}
.row63 {
  height: 33px;
}
.row71 {
  height: 50px;
}
.row72 {
  height: 49px;
}
.row73 {
  height: 27px;
}
.row81 {
  height: 40px;
}
.row82 {
  height: 49px;
}
.row83 {
  height: 49px;
}
.row91 {
  height: 20px;
}
.row92 {
  height: 31px;
}
.row93 {
  height: 46px;
}
.row101 {
  height: 32px;
}

.row23 {
  height: 30px;
}

.row74{
  height:26px;
}
</style>
