import { getPost, getGet } from '../api/apiList'
import qs from 'qs'

var global = {
  city: [
    { key: 'China(+86)', value: '86' },
    { key: 'Singapore(+65)', value: '65' },
    { key: 'Hong Kong SAR, China(+852)', value: '852' },
    { key: 'Macau SAR, China(+853)', value: '853' },
    { key: 'Taiwan(+886)', value: '886' },
    { key: 'Albania(+355)', value: '355' },
    { key: 'Algeria(+213)', value: '213' },
    { key: 'Afghanistan(+93)', value: '93' },
    { key: 'Argentina(+54)', value: '54' },
    { key: 'United Arab Emirates, Arabia(+971)', value: '971' },
    { key: 'Aruba(+297)', value: '297' },
    { key: 'Oman(+968)', value: '968' },
    { key: 'Azerbaijan(+994)', value: '994' },
    { key: 'Ascension(+247)', value: '247' },
    { key: 'Egypt(+20)', value: '20' },
    { key: 'Ethiopia(+251)', value: '251' },
    { key: 'Ireland(+353)', value: '353' },
    { key: 'Estonia(+372)', value: '372' },
    { key: 'Andorra(+376)', value: '376' },
    { key: 'Angola(+1264)', value: '1264' },
    { key: 'Anguilla(+244)', value: '244' },
    { key: 'The island of Antigua and barbuda(+1268)', value: '1268' },
    { key: 'Australia(+61)', value: '61' },
    { key: 'Austria(+43)', value: '43' },
    { key: 'Barbados(+1246)', value: '1246' },
    { key: 'Papua New Guinea(+675)', value: '675' },
    { key: 'Bahamas(+1809)', value: '1809' },
    { key: 'Pakistan(+92)', value: '92' },
    { key: 'Paraguay(+595)', value: '595' },
    { key: 'Palestine(+930)', value: '930' },
    { key: 'Bahrain(+973)', value: '973' },
    { key: 'Panama(+507)', value: '507' },
    { key: 'Brazil(+55)', value: '55' },
    { key: 'Belarus(+375)', value: '375' },
    { key: 'Bermuda(+1441)', value: '1441' },
    { key: 'Bulgaria(359+)', value: '359' },
    { key: 'The Northern Mariana islands(+1670)', value: '1670' },
    { key: 'Benin(+229)', value: '229' },
    { key: 'Belgium(+32)', value: '32' },
    { key: 'Iceland(+354)', value: '354' },
    { key: 'Puerto Rico(+1787)', value: '1787' },
    { key: 'Poland(+48)', value: '48' },
    { key: 'Bolivia(+591)', value: '591' },
    { key: 'Bosnia and herzegovina(+387)', value: '387' },
    { key: 'Botswana(+267)', value: '267' },
    { key: 'Belize(+501)', value: '501' },
    { key: 'Bhutan(+975)', value: '975' },
    { key: 'Burkina Faso(+226)', value: '226' },
    { key: 'Burundi(+257)', value: '257' },
    { key: 'North Korea(+850)', value: '850' },
    { key: 'Denmark(+45)', value: '45' },
    { key: 'Germany(+49)', value: '49' },
    { key: 'East Timor(+670)', value: '670' },
    { key: 'Togo(+228)', value: '228' },
    { key: 'Dominica(+1890)', value: '1890' },
    { key: 'The Republic of Dominica(+1809)', value: '1809' },
    { key: 'Russia(+7)', value: '7' },
    { key: 'Ecuador(+593)', value: '593' },
    { key: 'Eritrea(+291)', value: '291' },
    { key: 'France(+33)', value: '33' },
    { key: 'Faroe Islands(+298)', value: '298' },
    { key: 'French polynesia(+689)', value: '689' },
    { key: 'French guiana(+240)', value: '240' },
    { key: 'The French Southern territories(+33)', value: '33' },
    { key: 'The Vatican(+379)', value: '379' },
    { key: 'Philippines(+63)', value: '63' },
    { key: 'Fiji(+679)', value: '679' },
    { key: 'Finland(+358)', value: '358' },
    { key: 'Cape Verde(+238)', value: '238' },
    { key: 'The Frank islands(+49)', value: '49' },
    { key: 'Gambia(+220)', value: '220' },
    { key: 'Congo(+242)', value: '242' },
    { key: 'The Democratic Republic of Congo(+242)', value: '242' },
    { key: 'Columbia(+001)', value: '001' },
    { key: 'Costa Rica(+506)', value: '506' },
    { key: 'Guernsey(+502)', value: '502' },
    { key: 'Grenada(+1809)', value: '1809' },
    { key: 'Greenland(+45)', value: '45' },
    { key: 'Cuba(+53)', value: '53' },
    { key: 'Guadeloupe(+53)', value: '53' },
    { key: 'Guam(+1671)', value: '1671' },
    { key: 'Guyana(+592)', value: '592' },
    { key: 'Kazakhstan(+007)', value: '007' },
    { key: 'Haiti(+509)', value: '509' },
    { key: 'Korea(+82)', value: '82' },
    { key: 'Holland(+31)', value: '31' },
    { key: 'The Netherlands antilles(+599)', value: '599' },
    { key: 'Honduras(+504)', value: '504' },
    { key: 'Kiribati(+686)', value: '686' },
    { key: 'Djibouti(+253)', value: '253' },
    { key: 'Kyrgyzstan(+331)', value: '331' },
    { key: 'Guinea(+224)', value: '224' },
    { key: 'Guinea Bissau(+245)', value: '245' },
    { key: 'Canada(+001)', value: '001' },
    { key: 'Gabon(+241)', value: '241' },
    { key: 'Kampuchea(+855)', value: '855' },
    { key: 'Czech Republic(+420)', value: '420' },
    { key: 'Zimbabwe(+263)', value: '263' },
    { key: 'Cameroon(+237)', value: '237' },
    { key: 'Qatar(+974)', value: '974' },
    { key: 'The Cayman Islands(+1345)', value: '1345' },
    { key: 'The Cocos islands(+61)', value: '61' },
    { key: 'Comoros(+269)', value: '269' },
    { key: "Cote d'Ivoire(+225)", value: '225' },
    { key: 'Kuwait(+965)', value: '965' },
    { key: 'Croatia(+385)', value: '385' },
    { key: 'Kenya(+254)', value: '254' },
    { key: 'The Cook islands(+682)', value: '682' },
    { key: 'Latvia(+371)', value: '371' },
    { key: 'Lesotho(+266)', value: '266' },
    { key: 'Laos(+856)', value: '856' },
    { key: 'Lebanon(+961)', value: '961' },
    { key: 'Liberia(+231)', value: '231' },
    { key: 'Libya(+218)', value: '218' },
    { key: 'Lithuania(+370)', value: '370' },
    { key: 'Liechtenstein(+423)', value: '423' },
    { key: 'Reunion(+262)', value: '262' },
    { key: 'Luxemburg(+352)', value: '352' },
    { key: 'Rwanda(+250)', value: '250' },
    { key: 'Romania(+40)', value: '40' },
    { key: 'Madagascar(+261)', value: '261' },
    { key: 'Maldives(+960)', value: '960' },
    { key: 'Malta(+356)', value: '356' },
    { key: 'Malawi(+265)', value: '265' },
    { key: 'Malaysia(+60)', value: '60' },
    { key: 'Mali(+223)', value: '223' },
    { key: 'The Marshall islands(+692)', value: '692' },
    { key: 'Martinique(+596)', value: '596' },
    { key: 'Mayotte(+262)', value: '262' },
    { key: 'Mauritius(+230)', value: '230' },
    { key: 'Mauritania(+222)', value: '222' },
    { key: 'American Samoa(+685)', value: '685' },
    { key: 'Mongolia(+976)', value: '976' },
    { key: 'Montserrat(+1664)', value: '1664' },
    { key: 'Bangladesh(+880)', value: '880' },
    { key: 'Micronesia(+691)', value: '691' },
    { key: 'Peru(+51)', value: '51' },
    { key: 'Burma(+95)', value: '95' },
    { key: 'Moldova(+373)', value: '373' },
    { key: 'Morocco(+212)', value: '212' },
    { key: 'Monaco(+377)', value: '377' },
    { key: 'Mozambique(+258)', value: '258' },
    { key: 'Mexico(+52)', value: '52' },
    { key: 'Namibia(+264)', value: '264' },
    { key: 'South Africa(+27)', value: '27' },
    { key: 'Antarctica(+672)', value: '672' },
    { key: 'Nauru(+674)', value: '674' },
    { key: 'Nepal(+977)', value: '977' },
    { key: 'Nicaragua(+505)', value: '505' },
    { key: 'Niger(+227)', value: '227' },
    { key: 'Nigeria(+234)', value: '234' },
    { key: 'Niue(+683)', value: '683' },
    { key: 'Norway(+47)', value: '47' },
    { key: 'Norfolk(+6723)', value: '6723' },
    { key: 'Palau islands(+680)', value: '680' },
    { key: 'Portuguese(+80)', value: '80' },
    { key: 'Georgia(+995)', value: '995' },
    { key: 'Japan(+81)', value: '81' },
    { key: 'Sweden(+46)', value: '46' },
    { key: 'Switzerland(+41)', value: '41' },
    { key: 'Samoa(+684)', value: '684' },
    { key: 'Serbia, Montenegro(+381)', value: '381' },
    { key: 'Sierra Leone(+232)', value: '232' },
    { key: 'Senegal(+221)', value: '221' },
    { key: 'Cyprus(+357)', value: '357' },
    { key: 'Seychelles(+248)', value: '248' },
    { key: 'Saudi Arabia(+966)', value: '966' },
    { key: 'Christmas Island(+618)', value: '618' },
    { key: 'Sao Tome and principe(+239)', value: '239' },
    { key: 'St. helena(+290)', value: '290' },
    { key: 'Saint Kitts and nevis(+1784)', value: '1784' },
    { key: 'Lucia(+1758)', value: '1758' },
    { key: 'San marino(+378)', value: '378' },
    { key: 'Saint Vincent and the grenadines(+1784)', value: '1784' },
    { key: 'Sri Lanka(+94)', value: '94' },
    { key: 'Slovakia(+421)', value: '421' },
    { key: 'Slovenia(+386)', value: '386' },
    { key: 'Swaziland(+268)', value: '268' },
    { key: 'Suriname(+597)', value: '597' },
    { key: 'The Solomon islands(+677)', value: '677' },
    { key: 'Somalia(+252)', value: '252' },
    { key: 'Tajikistan(+992)', value: '992' },
    { key: 'Thailand(+66)', value: '66' },
    { key: 'Tanzania(+255)', value: '255' },
    { key: 'Tonga(+676)', value: '676' },
    { key: 'The Turks and Kaiketesi islands(+1649)', value: '1649' },
    { key: 'Tristan Da khuon ha(+992)', value: '992' },
    { key: 'Trinidad and Tobago(+1868)', value: '1868' },
    { key: 'Tunisia(+216)', value: '216' },
    { key: 'Tuvalu(+688)', value: '688' },
    { key: 'Tur{key(+90)', value: '90' },
    { key: 'Turkmenistan(+992)', value: '992' },
    { key: 'Tokelau(+690)', value: '690' },
    { key: 'Wallis and futuna(+681)', value: '681' },
    { key: 'Vanuatu(+678)', value: '678' },
    { key: 'Guatemala(+502)', value: '502' },
    { key: 'The Virgin Islands, American(+1340)', value: '1340' },
    { key: 'The Virgin Islands, the British(+1284)', value: '1284' },
    { key: 'Venezuela(+58)', value: '58' },
    { key: 'Brunei(+673)', value: '673' },
    { key: 'Uganda(+256)', value: '256' },
    { key: 'Ukraine(+380)', value: '380' },
    { key: 'Uruguay(+598)', value: '598' },
    { key: 'Uzbekistan(+233)', value: '233' },
    { key: 'Spain(+34)', value: '34' },
    { key: 'Greece(+30)', value: '30' },
    { key: 'New Caledonia(+687)', value: '687' },
    { key: 'New Zealand(+64)', value: '64' },
    { key: 'Hungary(+36)', value: '36' },
    { key: 'Syria(+963)', value: '963' },
    { key: 'Jamaica(+1876)', value: '1876' },
    { key: 'Armenia(+374)', value: '374' },
    { key: 'Yemen(+967)', value: '967' },
    { key: 'Iraq(+964)', value: '964' },
    { key: 'Iran(+98)', value: '98' },
    { key: 'Israel(+972)', value: '972' },
    { key: 'Italy(+39)', value: '39' },
    { key: 'India(+91)', value: '91' },
    { key: 'Indonesia(+62)', value: '62' },
    { key: 'Britain(+44)', value: '44' },
    { key: 'Jordan(+962)', value: '962' },
    { key: 'Vietnam(+84)', value: '84' },
    { key: 'Zambia(+260)', value: '260' },
    { key: 'Chad(+235)', value: '235' },
    { key: 'Gibraltar(+350)', value: '350' },
    { key: 'Chile(+56)', value: '56' },
    { key: 'The Central African Republic(+236)', value: '236' },
  ],
  bank: [
    { value: '新加坡银行有限公司', SwiftCode: 'INGPSGSG', original: '新加坡' },
    { value: '大华银行有限公司', SwiftCode: 'UOVBSGSG', original: '新加坡' },
    { value: '星展银行有限公司', SwiftCode: 'DBSSSGSG', original: '新加坡' },
    { value: '华侨银行有限公司', SwiftCode: 'OCBCSGSG', original: '新加坡' },

    { value: '中国银行股份有限公司', SwiftCode: 'BKCHSGSG', original: '中国' },
    { value: '中国工商银行', SwiftCode: 'ICBKSGSG', original: '中国' },
    { value: '交通银行新加坡分行', SwiftCode: 'COMMSGSG', original: '中国' },
    {
      value: '上海浦东发展银行股份有限公司',
      SwiftCode: 'SPDBSGSG',
      original: '中国',
    },
    {
      value: '中国农业银行股份有限公司新加坡分行',
      SwiftCode: 'ABOCSGSG',
      original: '中国',
    },
    { value: '招商银行新加坡分行', SwiftCode: 'CMBCSGSGPBD', original: '中国' },
    {
      value: '中国建设银行股份有限公司新加坡分行',
      SwiftCode: 'PCBCSGSGCIS',
      original: '中国',
    },
    {
      value: '中信银行国际有限公司新加坡分行',
      SwiftCode: 'KWHKSGSG',
      original: '中国',
    },
    {
      value: '中国信托银行股份有限公司新加坡分行',
      SwiftCode: 'CTCBSGSG',
      original: '中国',
    },
    { value: '兴业银行', SwiftCode: 'SOGESGSG', original: '中国' },
    { value: '东亚银行有限公司', SwiftCode: 'BEASSGSG', original: '中国' },
    { value: '台湾银行', SwiftCode: 'BKTWSGSG', original: '中国' },
    {
      value: '台湾土地银行新加坡分行',
      SwiftCode: 'LBOTSGSG',
      original: '中国',
    },

    { value: '联昌银行', SwiftCode: 'CIBBSGSG', original: '马来西亚' },
    {
      value: '马来亚银行有限公司',
      SwiftCode: 'MBBESGSG',
      original: '马来西亚',
    },
    { value: '新加坡丰隆银行', SwiftCode: 'HLBBSGSG', original: '马来西亚' },
    { value: '兴业银行有限公司', SwiftCode: 'RHBBSGSG', original: '马来西亚' },
    {
      value: '韩国开发银行新加坡分行',
      SwiftCode: 'KODBSGSG',
      original: '韩国',
    },
    { value: '三菱日联银行有限公司', SwiftCode: 'BOTKSGSX', original: '日本' },
    { value: '三井住友银行', SwiftCode: 'SMBCSGSG', original: '日本' },
    { value: '印度银行', SwiftCode: 'IDIBSGSG', original: '印度' },
    { value: '印度国家银行', SwiftCode: 'SBINSGSG', original: '印度' },
    { value: '曼谷银行股份有限公司', SwiftCode: 'BKKBSGSG', original: '泰国' },
    {
      value: '菲律宾国家银行-新加坡',
      SwiftCode: 'PNBMSGSG',
      original: '菲律宾',
    },
    {
      value: '卡塔尔国家银行 (QPSC)',
      SwiftCode: 'QNBASGSG',
      original: '卡塔尔',
    },
    {
      value: '沙特国家银行新加坡分行',
      SwiftCode: 'NCBKSGSG',
      original: '沙特',
    },

    {
      value: '美国银行（新加坡）有限公司',
      SwiftCode: 'BOFASG3XJVC',
      original: '美国',
    },
    {
      value: '摩根大通（海）有限公司',
      SwiftCode: 'CMSESGSG',
      original: '美国',
    },
    {
      value: '花旗银行新加坡有限公司',
      SwiftCode: 'CITISGSL',
      original: '美国',
    },
    {
      value: '渣打银行（新加坡）有限公司',
      SwiftCode: 'SCBLSG22',
      original: '美国',
    },
    {
      value: '汇丰银行（新加坡）有限公司',
      SwiftCode: 'HSBCSGS2',
      original: '英国',
    },
    { value: '巴克莱银行', SwiftCode: 'BARCSGSG', original: '英国' },
    { value: '加拿大皇家银行', SwiftCode: 'ROYCSGSGGSS', original: '加拿大' },
    { value: '加拿大帝国商业银行', SwiftCode: 'CIBCSGSG', original: '加拿大' },
    {
      value: '法国巴黎银行-新加坡分行',
      SwiftCode: 'BNPASGSGGCG',
      original: '法国 ',
    },
    {
      value: '德国商业银行新加坡分行',
      SwiftCode: 'COBASGSX',
      original: '德国 ',
    },
    { value: '德意志银行', SwiftCode: 'DEUTSGSR', original: '德国 ' },
    { value: '澳大利亚联邦银行', SwiftCode: 'CTBASGSG', original: '澳大利亚' },
    {
      value: '瑞士信贷银行新加坡分行',
      SwiftCode: 'CRESSGSG',
      original: '瑞士',
    },
    { value: '荷兰国际银行', SwiftCode: 'INGBSGSG', original: '荷兰' },
  ],
  date(format, timestamp) {
    var a,
      jsdate = timestamp ? new Date(timestamp * 1000) : new Date()
    var pad = function (n, c) {
      if ((n = n + '').length < c) {
        return new Array(++c - n.length).join('0') + n
      } else {
        return n
      }
    }
    var ret
    var txt_weekdays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    var txt_ordin = {
      1: 'st',
      2: 'nd',
      3: 'rd',
      21: 'st',
      22: 'nd',
      23: 'rd',
      31: 'st',
    }
    var txt_months = [
      '',
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    var f = {
      // Day
      d: function () {
        return pad(f.j(), 2)
      },
      D: function () {
        return f.l().substr(0, 3)
      },
      j: function () {
        return jsdate.getDate()
      },
      l: function () {
        return txt_weekdays[f.w()]
      },
      N: function () {
        return f.w() + 1
      },
      S: function () {
        return txt_ordin[f.j()] ? txt_ordin[f.j()] : 'th'
      },
      w: function () {
        return jsdate.getDay()
      },
      z: function () {
        return ((jsdate - new Date(jsdate.getFullYear() + '/1/1')) / 864e5) >> 0
      },

      // Week
      W: function () {
        var a = f.z(),
          b = 364 + f.L() - a
        var nd2,
          nd = (new Date(jsdate.getFullYear() + '/1/1').getDay() || 7) - 1
        if (b <= 2 && (jsdate.getDay() || 7) - 1 <= 2 - b) {
          return 1
        } else {
          if (a <= 2 && nd >= 4 && a >= 6 - nd) {
            nd2 = new Date(jsdate.getFullYear() - 1 + '/12/31')
            return date('W', Math.round(nd2.getTime() / 1000))
          } else {
            return (1 + (nd <= 3 ? (a + nd) / 7 : (a - (7 - nd)) / 7)) >> 0
          }
        }
      },

      // Month
      F: function () {
        return txt_months[f.n()]
      },
      m: function () {
        return pad(f.n(), 2)
      },
      M: function () {
        return f.F().substr(0, 3)
      },
      n: function () {
        return jsdate.getMonth() + 1
      },
      t: function () {
        var n
        if ((n = jsdate.getMonth() + 1) == 2) {
          return 28 + f.L()
        } else {
          if ((n & 1 && n < 8) || (!(n & 1) && n > 7)) {
            return 31
          } else {
            return 30
          }
        }
      },

      // Year
      L: function () {
        var y = f.Y()
        return !(y & 3) && (y % 1e2 || !(y % 4e2)) ? 1 : 0
      },
      //o not supported yet
      Y: function () {
        return jsdate.getFullYear()
      },
      y: function () {
        return (jsdate.getFullYear() + '').slice(2)
      },

      // Time
      a: function () {
        return jsdate.getHours() > 11 ? 'pm' : 'am'
      },
      A: function () {
        return f.a().toUpperCase()
      },
      B: function () {
        // peter paul koch:
        var off = (jsdate.getTimezoneOffset() + 60) * 60
        var theSeconds =
          jsdate.getHours() * 3600 +
          jsdate.getMinutes() * 60 +
          jsdate.getSeconds() +
          off
        var beat = Math.floor(theSeconds / 86.4)
        if (beat > 1000) beat -= 1000
        if (beat < 0) beat += 1000
        if (String(beat).length == 1) beat = '00' + beat
        if (String(beat).length == 2) beat = '0' + beat
        return beat
      },
      g: function () {
        return jsdate.getHours() % 12 || 12
      },
      G: function () {
        return jsdate.getHours()
      },
      h: function () {
        return pad(f.g(), 2)
      },
      H: function () {
        return pad(jsdate.getHours(), 2)
      },
      i: function () {
        return pad(jsdate.getMinutes(), 2)
      },
      s: function () {
        return pad(jsdate.getSeconds(), 2)
      },
      //u not supported yet

      // Timezone
      //e not supported yet
      //I not supported yet
      O: function () {
        var t = pad(Math.abs((jsdate.getTimezoneOffset() / 60) * 100), 4)
        if (jsdate.getTimezoneOffset() > 0) t = '-' + t
        else t = '+' + t
        return t
      },
      P: function () {
        var O = f.O()
        return O.substr(0, 3) + ':' + O.substr(3, 2)
      },
      //T not supported yet
      //Z not supported yet

      // Full Date/Time
      c: function () {
        return (
          f.Y() +
          '-' +
          f.m() +
          '-' +
          f.d() +
          'T' +
          f.h() +
          ':' +
          f.i() +
          ':' +
          f.s() +
          f.P()
        )
      },
      //r not supported yet
      U: function () {
        return Math.round(jsdate.getTime() / 1000)
      },
    }

    return format.replace(/([a-zA-Z])/g, function (t, s) {
      if (t != s) {
        // escaped
        ret = s
      } else if (f[s]) {
        // a date function exists
        ret = f[s]()
      } else {
        // nothing special
        ret = s
      }
      return ret
    })
  },
  getqiyeinfo() {
    var data2 = {}
    var url = `/stores/tdd/qy_member/bind_info?wxapp_id=10001&token=${this.token}`
    qiyeBind(url, data2).then((res) => {
      if (res.code == 1) {
        this.rzname = res.data.qy_name
        this.rzaddr = res.data.qy_address

        var qystime = this.date('Y-m', res.data.stime)
        var qyetime = this.date('Y-m', res.data.etime)

        sessionStorage.setItem('qystime', qystime)
        sessionStorage.setItem('qyetime', qyetime)

        sessionStorage.setItem('stime', qystime)
        sessionStorage.setItem('etime', qyetime)

        sessionStorage.setItem('qy_guid', res.data.guid)

        sessionStorage.setItem('qy_name', res.data.qy_name)
        sessionStorage.setItem('qy_addr', res.data.qy_address)
        sessionStorage.setItem('qy_conum', res.data.house_num)

        sessionStorage.setItem('qyzq_admin', res.data.is_admin)

        if (
          res.data.ccer_pic == 'null' ||
          res.data.ccer_pic == null ||
          res.data.ccer_pic == ''
        ) {
          sessionStorage.setItem('ccerPic', 0)
        } else {
          sessionStorage.setItem('ccerPic', res.data.ccer_pic)
        }

        this.rzzhouqi = qystime + '至' + qyetime

        this.qyadmin = res.data.is_admin
        this.guid = res.data.guid

        this.qybind = res.data.is_bind
      } else {
        Toast(res.msg)
      }
    })
  },
}

export { global }
