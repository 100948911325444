<template>
  <div>
    <div class="fixTop">
      <div class="safeW">
        <div class="tL">
          <div
            class="bigLogo"
            v-if="bigScreen"
            @click="goindex"
            style="cursor: pointer"
          >
            <img src="../assets/images/xuexi/logo.png" />
          </div>
          <div class="tLflex">
            <div
              class="l"
              v-if="!bigScreen"
              @click="goindex"
              style="cursor: pointer"
            >
              <img src="../assets/images/xuexi/logo.png" />
            </div>
            <div class="r" v-if="bigScreen">
              <span :class="{ hit: currentNav == 1 }" @click="goinvest"
                >Invest</span
              >
              <span :class="{ hit: currentNav == 4 }" @click="goTransaction"
                >Market</span
              >
              <span :class="{ hit: currentNav == 2 }" @click="goknowledge"
                >Knowledge</span
              >
              <span
                :class="{ hit: currentNav == 3 }"
                @click="$router.push('/aboutus/news')"
                >About</span
              >
            </div>
            <div class="r2" v-if="!bigScreen">
              <span :class="{ hit: currentNav == 1 }" @click="goinvest"
                >Invest</span
              >
              <span :class="{ hit: currentNav == 4 }" @click="goTransaction"
                >Market</span
              >
              <span :class="{ hit: currentNav == 2 }" @click="goknowledge"
                >Knowledge</span
              >
              <span
                :class="{ hit: currentNav == 3 }"
                @click="$router.push('/aboutus/news')"
                >About</span
              >
            </div>
          </div>
        </div>
        <div class="tR" v-if="!isLogin">
          <div class="reg" @click="goreg">
            <img src="../assets/images/xuexi/reg.png" />
          </div>
          <div class="login" @click="gologin">Log In</div>
          <div class="lang">
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link langu">
                <span>
                  <img
                    class="img80"
                    src="../assets/images/xuexi/en.jpg"
                  /> </span
                ><i
                  class="el-icon-arrow-down el-icon--right"
                  style="font-weight: 700"
                ></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="cn">
                  <div class="langu">
                    <img class="img100" src="../assets/images/index/jian.jpg" />
                  </div>
                </el-dropdown-item>
                <el-dropdown-item command="fanti">
                  <div class="langu">
                    <img
                      class="img100"
                      src="../assets/images/index/fanti.png"
                    />
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="tR" v-if="isLogin">
          <div class="flex" v-if="personalok == 1">
            <div class="l">
              <el-dropdown trigger="click" @command="handleCommand">
                <span class="el-dropdown-link langu">
                  <span
                    ><img
                      class="img80"
                      src="../assets/images/xuexi/en.jpg" /></span
                  ><i
                    class="el-icon-arrow-down el-icon--right"
                    style="font-weight: 700"
                  ></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="cn">
                    <div class="langu">
                      <img
                        class="img100"
                        src="../assets/images/index/jian.jpg"
                      />
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item command="fanti">
                    <div class="langu">
                      <img
                        class="img100"
                        src="../assets/images/index/fanti.png"
                      />
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div
              style="margin-right: 27px; cursor: pointer"
              @click="
                $router.push({
                  name: 'wallet',
                })
              "
            >
              <img
                src="../assets/images/mycenter/qianbao.png"
                class="img50"
                alt=""
              />
            </div>
            <div style="margin-right: 27px">
              <el-dropdown placement="bottom" :hide-timeout="1000">
                <div
                  @click="
                    $router.push({
                      name: 'messageList',
                    })
                  "
                >
                  <el-badge
                    :value="total_count ? total_count : ''"
                    class="badge"
                    :max="99"
                  >
                    <img
                      src="../assets/images/mycenter/msg11.png"
                      class="img50"
                      alt=""
                    />
                  </el-badge>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <div class="messageBox">
                    <div class="flexBox" style="justify-content: space-between">
                      <div>
                        <img
                          src="@/assets/images/mycenter/msg2.png"
                          alt=""
                          width="15px"
                        />
                        <span
                          style="
                            font-weight: bold;
                            font-size: 18px;
                            margin-left: 7px;
                          "
                          >Notifications</span
                        >
                      </div>
                      <div
                        @click="
                          $router.push({
                            name: 'setting',
                            query: {
                              active: 2,
                            },
                          })
                        "
                      >
                        <i class="el-icon-setting"></i>
                      </div>
                    </div>
                    <div class="fiveMsgList">
                      <div
                        class="item"
                        v-for="(item, index) in unReadMessage"
                        :key="index"
                      >
                        <div
                          class="flexBox"
                          @click="
                            $router.push({
                              name: 'messageDetail',
                              params: {
                                id: item.message_guid,
                              },
                            })
                          "
                        >
                          <div style="margin-right: 15px">
                            <div class="logoBox">
                              <img :src="item.logo_url" alt="" />
                            </div>
                          </div>
                          <div style="flex: 1">
                            <div
                              class="flexBox"
                              style="justify-content: space-between"
                            >
                              <div>{{ item.publisher }}</div>
                              <div style="color: #bebebe">{{ item.ctime }}</div>
                            </div>
                            <div
                              style="
                                font-size: 14px;
                                font-weight: bold;
                                line-height: 30px;
                              "
                            >
                              {{ item.title }}
                            </div>

                            <div class="content">
                              {{ item.abstract }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="!unReadMessage.length"
                        style="text-align: center"
                      >
                        No notifications
                      </div>
                    </div>
                    <div
                      v-if="!!unReadMessage.length"
                      class="readMore"
                      @click="
                        $router.push({
                          name: 'messageList',
                        })
                      "
                    >
                      More
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <el-dropdown placement="bottom-end">
              <span class="el-dropdown-link">
                <div
                  class="m"
                  @click="
                    $router.push({
                      name: 'home',
                    })
                  "
                >
                  <img
                    class="img50"
                    src="../assets/images/mycenter/people.png"
                    alt=""
                  />
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div class="flexBox">
                    <div style="margin-right: 21px">
                      <img
                        class="img50"
                        style="margin-top: 15px"
                        src="../assets/images/mycenter/people.png"
                        alt=""
                      />
                    </div>
                    <div>
                      <div class="name">
                        {{ email }}
                      </div>
                      <div class="info">
                        <span v-if="categ == 1">Individual Investor</span>
                        <span v-if="categ == 2">Corporate Investor</span>
                      </div>
                    </div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div
                    class="droItem"
                    @click="
                      $router.push({
                        name: 'setting',
                      })
                    "
                  >
                    <img
                      class="setimg"
                      src="../assets/images/mycenter/set.png"
                      alt=""
                    />
                    <span>Settings</span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div
                    class="droItem"
                    @click="
                      $router.push({
                        name: 'fileList',
                      })
                    "
                  >
                    <img
                      class="fileimg"
                      style="margin-right: 17px"
                      src="../assets/images/mycenter/file.png"
                      alt=""
                    />
                    <span>Documents</span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div
                    class="droItem"
                    @click="
                      $router.push({
                        path: '/my/coupon',
                      })
                    "
                  >
                    <img
                      class="shareimg"
                      style="margin-right: 11x"
                      src="../assets/images/mycenter/share.png"
                      alt=""
                    />
                    <span>Invite friends</span>
                    <img
                      class="usdimg"
                      style="margin-left: 16px"
                      src="../assets/images/mycenter/组 7714.png"
                      alt=""
                    />
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div
                    class="droItem"
                    @click="
                      $router.push({
                        name: 'knowledge',
                      })
                    "
                  >
                    <img
                      class="helpimg"
                      style="margin-right: 11x"
                      src="../assets/images/mycenter/help.png"
                      alt=""
                    />
                    <span>Help</span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div class="droItem" @click="logout">
                    <img
                      class="outimg"
                      style="margin-right: 13x"
                      src="../assets/images/mycenter/out.png"
                      alt=""
                    />
                    <span style="color: #ce0d1b">Sign out</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div class="flexTwo" v-else-if="personalok == 0">
            <div class="l">
              <el-dropdown trigger="click" @command="handleCommand">
                <div class="el-dropdown-link langu">
                  <span
                    ><img class="img80" src="../assets/images/xuexi/en.jpg"
                  /></span>
                  <i
                    class="el-icon-arrow-down el-icon--right"
                    style="font-weight: 700"
                  ></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="cn">
                    <div class="langu">
                      <span
                        ><img
                          class="img100"
                          src="../assets/images/index/jian.jpg"
                      /></span>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item command="fanti">
                    <div class="langu">
                      <img
                        class="img100"
                        src="../assets/images/index/fanti.png"
                      />
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="m emailcss">{{ email }}</div>
            <div class="r" @click="logout">Sign out</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'NavButton',
  props: ['currentNav'],
  data() {
    return {
      bigScreen: true,
      token: sessionStorage.getItem('token'),
      isLogin: false,
      email: '',
      fill: '#333',
      personalok: 0,
      categ: '',
    }
  },
  mounted() {
    const isFox = navigator.userAgent.indexOf('Firefox') > -1 ? true : false

    console.log($(window).width())
    var sW = $(window).width()
    if (sW >= 1504) {
      this.bigScreen = true
    } else {
      this.bigScreen = false
    }

    // if (isFox) {
    //   if (sW <= 1600) {
    //     $('.bigLogo').css('left', '-150px')
    //   }
    // }
    this.change()
    this.init()
  },
  methods: {
    ...mapActions(['asyncSetMessage']),
    init() {
      if (this.isLogin) {
        this.asyncSetMessage()
      }
    },
    handleCommand(command) {
      if (command === 'cn') {
        sessionStorage.setItem('cnType', 'jian')
        this.lang_change()
      }
      if (command === 'fanti') {
        sessionStorage.setItem('cnType', 'fan')
        this.lang_change()
      }
    },
    lang_change() {
      let url = [
        {
          url: 'aboutus/news/detail',
          replace: 'aboutus/news',
        },
        {
          url: 'aboutus/activity/Detail',
          replace: 'aboutus/activity',
        },
        {
          url: 'aboutus/blog/Detail',
          replace: 'aboutus/blog',
        },
      ]

      const flag = url.some((item) => {
        return window.location.href.includes(item.url)
      })

      if (flag) {
        const index = url.findIndex((item) =>
          window.location.href.includes(item.url)
        )
        let href = window.location.href.replace('en', 'zhcn')
        const index2 = href.indexOf(url[index].url)
        href = href.slice(0, index2) + url[index].replace
        window.location.replace(href)
      } else {
        window.location.replace(window.location.href.replace('en', 'zhcn'))
      }
    },
    change() {
      this.token = sessionStorage.getItem('token')
      this.email = sessionStorage.getItem('email')
      this.personalok = sessionStorage.getItem('personalok')
      this.categ = sessionStorage.getItem('categ')
      if (this.personalok == 1 || this.personalok == 0) {
        this.isLogin = true
      } else {
        this.isLogin = false
      }
    },

    logout() {
      this.$confirm('Are you sure you want to exit?', {
        confirmButtonClass: 'confirm',
      })
        .then((_) => {
          sessionStorage.setItem('token', '')
          sessionStorage.setItem('email', '')
          sessionStorage.setItem('personalok', -1)
          this.isLogin = false
          this.$router.push({
            path: '/login',
          })
        })
        .catch((_) => {})
    },

    gologin() {
      this.$router.push({
        path: '/login',
      })
    },

    goknowledge() {
      this.$router.push({
        path: '/knowledge',
      })
    },
    goindex() {
      this.$router.push({
        path: '/index',
      })
    },
    goinvest() {
      this.$router.push({
        path: '/investList',
      })
    },
    goreg() {
      this.$router.push({
        path: '/reg',
      })
    },
    goTransaction() {
      this.$router.push({
        path: '/transaction',
      })
    },

    move() {
      this.fill = '#3698f8'
    },
  },
  computed: {
    ...mapState(['total_count', 'unReadMessage']),
  },
}
</script>

<style lang="less" scoped>
.fixTop {
  position: fixed;
  height: 80px;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  z-index: 1000;
}

.safeW {
  width: 1300px;
  margin: 0 auto;
  position: relative;
}

.tL {
  font-size: 20px;
  color: #333;
  position: absolute;
  left: 0;
  top: 0;

  .tLflex {
    margin-left: 30px;
    height: 80px;
    display: flex;
    align-items: center;
    .l {
      margin-right: 30px;
      > img {
        margin-right: 24px;
        width: 236px;
      }
    }
    .r {
      > span {
        margin-right: 75px;
        cursor: pointer;
        font-weight: bold;
        font-size: 24px;
        &.hit {
          color: #ce0d1b;
        }
      }
    }

    .r2 {
      > span {
        margin-right: 55px;
        cursor: pointer;
        font-weight: bold;
        font-size: 24px;
        &.hit {
          color: #ce0d1b;
        }
      }
    }
  }
}

.tR {
  font-family: 'Reguluar';
  position: absolute;
  right: 0;
  top: 0;
  .reg {
    position: absolute;
    right: 10px;
    top: 20px;
    cursor: pointer;
    > img {
      width: 114px;
    }
  }

  .flex {
    display: flex;
    align-items: center;
    margin-top: 13px;
    .l {
      font-size: 20px;
      color: #333;
      cursor: pointer;
      position: relative;
      margin-right: 30px;
      > span {
        position: absolute;
        left: -25px;
        top: 3px;
      }
    }
    .m {
      color: #333;
      font-size: 20px;
      cursor: pointer;
      margin-right: 30px;
    }
    .r {
      color: #333;
      font-size: 20px;
      border-bottom: 1px solid #333;
      cursor: pointer;
    }
  }

  .flexTwo {
    display: flex;
    align-items: center;
    margin-top: 26px;
    .l {
      font-size: 20px;
      color: #333;
      cursor: pointer;
      position: relative;
      margin-right: 30px;
      > span {
        position: absolute;
        left: -25px;
        top: 3px;
      }
    }
    .m {
      img {
        width: 50px;
        height: 50px;
      }
    }
    .r {
      color: #333;
      font-size: 20px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .reg2 {
    position: absolute;
    right: 10px;
    top: 20px;
    cursor: pointer;
    width: 100px;
    color: #333;
    font-size: 20px;
    text-decoration: underline;
  }

  .login {
    position: absolute;
    right: 160px;
    top: 33px;
    cursor: pointer;
    font-size: 20px;
    color: #333;
    width: 60px;
  }

  .lang {
    position: absolute;
    right: 250px;
    top: 30px;
    font-size: 20px;
    white-space: nowrap;
    color: #333;
    cursor: pointer;
    > span {
      position: absolute;
      left: -25px;
      top: 3px;
      > img {
        width: 19px;
      }
    }
  }
}

.bigLogo {
  position: absolute;
  left: -256px;
  top: 23px;
  > img {
    width: 236px;
  }
}

.name {
  font-size: 20px;
  font-weight: 500;
  color: #2f2f2f;
}
.info {
  font-size: 16px;
  font-weight: 400;
  color: #bebebe;
}
.droItem {
  height: 50px;
  line-height: 50px;
  img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 13px;
  }
  span {
    font-size: 20px;
    vertical-align: middle;
    font-weight: 400;
    color: #2f2f2f;
  }
}
::v-deep.badge .el-badge__content.is-fixed {
  top: 12px;
  right: 22px;
}
.messageBox {
  width: 450px;
  padding: 14px;
  padding-bottom: 5px;
}
.el-icon-setting {
  cursor: pointer;
  font-size: 17px;
}
.fiveMsgList {
  margin-top: 26px;
  .item {
    cursor: pointer;
    background: #f3f5fb;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 10px;
    .content {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      font-size: 14px;
      color: #bebebe;
    }
  }
}
.logoBox {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  img {
    width: 100%;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.readMore {
  text-align: end;
  font-size: 16px;
  color: #ce0d1b;
  text-decoration: underline;
  cursor: pointer;
}

.img50 {
  width: 50px;
}

.lang19 {
  width: 19px;
}

.setimg {
  width: 23px;
}

.fileimg {
  width: 19px;
}

.shareimg {
  width: 25px;
}

.usdimg {
  width: 119px;
}

.helpimg {
  width: 24px;
}

.outimg {
  width: 22px;
}

.emailcss {
  font-size: 20px;
  padding-right: 30px;
}
.img30 {
  width: 35px;
}
.langu {
  font-size: 22px;
}
.el-dropdown-menu__item:not(.is-disabled):hover {
  //鼠标悬停样式
  color: #ce0d1b !important;
}
.img100 {
  width: 100px;
}
.img80 {
  width: 110px;
}
.el-icon-arrow-down {
  position: relative;
  top: -3px;
  left: -7px;
}
</style>
