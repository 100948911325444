import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/index.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => err)
}
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
  },
  {
    path: '/knowledge',
    name: 'knowledge',
    component: () => import('@/views/knowledge.vue'),
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index.vue'),
  },
  {
    path: '/investList',
    name: 'investList',
    component: () => import('@/views/investList.vue'),
  },
  {
    path: '/investDetailPreview',
    name: 'investDetailPreview',
    component: () => import('@/views/investDetailPreview.vue'),
  },
  {
    path: '/investDetail',
    name: 'investDetail',
    component: () => import('@/views/investDetail.vue'),
  },
  {
    path: '/reg',
    name: 'reg',
    component: () => import('@/views/reg.vue'),
  },
  {
    path: '/reg_co',
    name: 'reg_co',
    component: () => import('@/views/reg_co.vue'),
  },
  {
    path: '/p_basic',
    name: 'p_basic',
    component: () => import('@/views/p_basic.vue'),
  },
  {
    path: '/p_address',
    name: 'p_address',
    component: () => import('@/views/p_address.vue'),
  },
  {
    path: '/p_finance',
    name: 'p_finance',
    component: () => import('@/views/p_finance.vue'),
  },
  {
    path: '/p_declare',
    name: 'p_declare',
    component: () => import('@/views/p_declare.vue'),
  },
  {
    path: '/transaction',
    name: 'transaction',
    component: () => import('@/views/transaction.vue'),
  },
  {
    path: '/p_prove',
    name: 'p_prove',
    component: () => import('@/views/p_prove.vue'),
  },
  {
    path: '/p_face',
    name: 'p_face',
    component: () => import('@/views/p_face.vue'),
  },
  {
    path: '/p_crs',
    name: 'p_crs',
    component: () => import('@/views/p_crs.vue'),
  },
  {
    path: '/p_review',
    name: 'p_review',
    component: () => import('@/views/p_review.vue'),
  },
  {
    path: '/co_basic',
    name: 'co_basic',
    component: () => import('@/views/co_basic.vue'),
  },
  {
    path: '/co_company',
    name: 'co_company',
    component: () => import('@/views/co_company.vue'),
  },
  {
    path: '/co_address',
    name: 'co_address',
    component: () => import('@/views/co_address.vue'),
  },
  {
    path: '/co_review',
    name: 'co_review',
    component: () => import('@/views/co_review.vue'),
  },

  {
    path: '/co_declare',
    name: 'co_declare',
    component: () => import('@/views/co_declare.vue'),
  },
  {
    path: '/regtel',
    name: 'regtel',
    component: () => import('@/views/regtel.vue'),
  },
  {
    path: '/regemail',
    name: 'regemail',
    component: () => import('@/views/regemail.vue'),
  },
  {
    path: '/regemailstatic',
    name: 'regemailstatic',
    component: () => import('@/views/regemailstatic.vue'),
  },
  {
    path: '/regemaildie',
    name: 'regemaildie',
    component: () => import('@/views/regemaildie.vue'),
  },
  {
    path: '/regemailre',
    name: 'regemailre',
    component: () => import('@/views/regemailre.vue'),
  },
  {
    path: '/logincode',
    name: 'logincode',
    component: () => import('@/views/logincode.vue'),
  },
  {
    path: '/h5_upload',
    name: 'h5_upload',
    component: () => import('@/views/h5_upload.vue'),
  },
  {
    path: '/h5_face',
    name: 'h5_face',
    component: () => import('@/views/h5_face.vue'),
  },
  {
    path: '/h5_face2',
    name: 'h5_face2',
    component: () => import('@/views/h5_face2.vue'),
  },
  {
    path: '/h5_tijiao',
    name: 'h5_tijiao',
    component: () => import('@/views/h5_tijiao.vue'),
  },
  {
    path: '/h5_shenhe',
    name: 'h5_shenhe',
    component: () => import('@/views/h5_shenhe.vue'),
  },
  {
    path: '/formCheck',
    name: 'formCheck',
    component: () => import('@/views/formCheck.vue'),
  },

  {
    path: '/forgetpwdemail',
    name: 'forgetpwdemail',
    component: () => import('@/views/forgetpwdemail.vue'),
  },
  {
    path: '/forgetpwdemailstatic',
    name: 'forgetpwdemailstatic',
    component: () => import('@/views/forgetpwdemailstatic.vue'),
  },
  {
    path: '/forgetpwd',
    name: 'forgetpwd',
    component: () => import('@/views/forgetpwd.vue'),
  },
  {
    path: '/aboutus/news',
    name: 'aboutus',

    component: () => import('@/views/AboutUs'),
    children: [
      {
        path: '/aboutus/news',
        name: 'news',
        component: () => import('@/views/AboutUs/news'),
      },
      {
        path: '/aboutus/activity',
        name: 'activity',
        component: () => import('@/views/AboutUs/activity'),
      },
      {
        path: '/aboutus/blog',
        name: 'blog',
        component: () => import('@/views/AboutUs/blog'),
      },
      {
        path: '/aboutus/job',
        name: 'job',
        component: () => import('@/views/AboutUs/job'),
      },
      {
        path: '/aboutus/team',
        name: 'team',
        component: () => import('@/views/AboutUs/team'),
      },
      {
        path: '/aboutus/news/Detail/:id',
        name: 'newsDetail',
        component: () => import('@/views/AboutUs/newsDetail'),
      },
      {
        path: '/aboutus/activity/Detail/:id',
        name: 'activityDetail',
        component: () => import('@/views/AboutUs/activityDetail'),
      },
      {
        path: '/aboutus/blog/Detail/:id',
        name: 'blogDetail',
        component: () => import('@/views/AboutUs/blogDetail'),
      },
    ],
  },
  {
    path: '/my/home',
    name: 'my',
    component: () => import('@/views/My'),
    children: [
      {
        path: '/my/home',
        name: 'home',
        component: () => import('@/views/My/home.vue'),
      },
      {
        path: '/my/messageList',
        name: 'messageList',
        component: () => import('@/views/My/messageList.vue'),
      },
      {
        path: '/my/messageDetail/:id',
        name: 'messageDetail',
        component: () => import('@/views/My/messageDetail.vue'),
      },
      {
        path: '/my/fileList',
        name: 'fileList',
        component: () => import('@/views/My/fileList.vue'),
      },
      {
        path: '/my/setting',
        name: 'setting',
        component: () => import('@/views/My/setting.vue'),
      },
      {
        path: '/my/addEbank',
        name: 'addEbank',
        component: () => import('@/views/My/addEBank.vue'),
      },

      {
        path: '/my/wallet',
        name: 'wallet',
        component: () => import('@/views/My/wallet.vue'),
      },
      {
        path: '/my/coupon',
        name: 'coupon',
        component: () => import('@/views/My/coupon.vue'),
      },
      {
        path: '/my/myInvest',
        name: 'myInvest',
        component: () => import('@/views/My/myInvest.vue'),
      },
      {
        path: '/my/bandCardSet',
        name: 'bandSet',
        component: () => import('@/views/My/bandSet.vue'),
      },
      {
        path: '/my/rjpay',
        name: 'rjpay',
        component: () => import('@/views/My/rjpay.vue'),
      },
      {
        path: '/my/rjbank',
        name: 'rjbank',
        component: () => import('@/views/My/rjbank.vue'),
      },
      {
        path: '/my/walletthird',
        name: 'walletthird',
        component: () => import('@/views/My/walletthird.vue'),
      },
      {
        path: '/my/jydetail',
        name: 'jydetail',
        component: () => import('@/views/My/jydetail.vue'),
      },
      {
        path: '/my/xxb',
        name: 'xxb',
        component: () => import('@/views/xxb/index.vue'),
      },
      {
        path: '/my/temp',
        name: 'temp',
        component: () => import('@/views/xxb/temp.vue'),
      },
      {
        path: '/my/xxbdetail',
        name: 'xxbdetail',
        component: () => import('@/views/xxb/xxbdetail.vue'),
      },
      {
        path: '/my/investedDetail',
        name: 'investedDetail',
        component: () => import('@/views/My/investedDetail.vue'),
      },
      {
        path: '/ethdemo',
        name: 'walletPlay',
        component: () => import('@/views/walletPlay.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'hash',

  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log(to, from, savedPosition)
    return { x: 0, y: 0 }
  },
})

export default router
